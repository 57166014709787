<script setup lang="ts">
type Option = {
  value: number | string | boolean
  label: string
  icon?: string
  image?: string
}

const props = defineProps<{
  modelValue?: null | number | string | boolean
  options: Option[]
  disabled?: boolean
}>()

const emit = defineEmits(['update:modelValue'])

function update({ value }: Option) {
  if (!props.disabled) {
    emit('update:modelValue', value)
  }
}
</script>

<template>
  <div class="about__tabs">
    <div class="about__tabs-inner">
      <button
        v-for="option in props.options"
        @click="update(option)"
        :disabled="props.disabled"
        type="button"
        class="about__tabs-item"
        :class="{ 'is-active': option.value === props.modelValue }"
        :key="String(option.value)"
      >
        <span>{{ option.label }}</span>
      </button>
    </div>
  </div>
</template>

<style scoped></style>
