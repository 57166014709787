<script setup lang="ts">
import { useHead } from '@unhead/vue'

import TheAboutSection from '@/components/home/sections/TheAboutSection.vue'
import TheBannerSection from '@/components/home/sections/TheBannerSection.vue'
import TheCreateTokenSection from '@/components/home/sections/TheCreateTokenSection.vue'
import TheExpectationsSection from '@/components/home/sections/TheExpectationsSection.vue'
import TheFaqSection from '@/components/home/sections/TheFaqSection.vue'
import TheGetStartedSection from '@/components/home/sections/TheGetStartedSection.vue'
import TheInfoSection from '@/components/home/sections/TheInfoSection.vue'
import TheServicesSection from '@/components/home/sections/TheServicesSection.vue'

useHead({
  title: 'Home | 1Dapp: crypto project generator'
})
</script>

<template>
  <TheBannerSection id="main" />

  <TheInfoSection id="info" />

  <TheAboutSection id="products" />

  <TheCreateTokenSection id="token" />

  <TheServicesSection id="extra" />

  <TheExpectationsSection />

  <TheFaqSection />

  <TheGetStartedSection />
</template>

<style lang="scss" scoped></style>
