<script setup lang="ts">
import { onBeforeUnmount, onMounted, reactive, ref } from 'vue'

import Icon from '@1dapp/ui/components/Icon.vue'

import type { ServiceType } from '@/types'

const props = defineProps<{
  item: ServiceType
}>()

const buttonRef = ref()
const state = reactive({
  show: false
})

function clickHandler(e: Event) {
  if (buttonRef.value) {
    if (buttonRef.value.contains(e.target)) {
      state.show = !state.show
    } else if (state.show) {
      state.show = false
    }
  }
}

onMounted(() => {
  document.addEventListener('click', clickHandler)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', clickHandler)
})
</script>

<template>
  <div
    class="services__item"
    :class="[
      props.item.colorClass,
      {
        'is-main': !('image' in props.item),
        'is-active': state.show
      }
    ]"
  >
    <template v-if="'image' in props.item">
      <div class="services__item-top">
        <div class="services__item-icon">
          <img :src="props.item.image" :alt="props.item.title" width="32" height="32" />
        </div>

        <div class="services__item-title">{{ props.item.title }}</div>

        <button ref="buttonRef" :aria-label="props.item.title" type="button" class="services__item-btn">
          <Icon src="info" />
        </button>
      </div>

      <div class="services__item-text">{{ props.item['text'] }}</div>

      <div class="services__item-inner">
        <RouterLink v-if="!!props.item.demo" :to="props.item.demo" target="_blank" class="btn btn--tertiary">
          <div class="btn__icon">
            <Icon src="play" />
          </div>
          <span>Watch Demo</span>
        </RouterLink>

        <a v-if="!!props.item.docs" :href="props.item.docs" target="_blank" class="btn btn--tertiary">
          <div class="btn__icon">
            <Icon src="docs" />
          </div>
          <span>Read more</span>
        </a>
      </div>
    </template>

    <div v-else v-html="props.item.title" class="services__item-topic"></div>
  </div>
</template>

<style scoped></style>
