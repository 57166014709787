<script setup lang="ts">
import { ref } from 'vue'

import Picture from '@/components/Picture.vue'

const activeCard = ref('box1-active')
const infoCards = [
  {
    class: 'info__box--blue',
    toggle: 'box1-active',
    title: 'Create',
    mark: 'website',
    text: 'You can create a fully functional crypto website for your project. Use our powerful no-code generator with lots of different templates and extra features. Add your domain and post the website on the web using fast & secure 1Dapp hosting.',
    image: require('@/assets/images/useful/info/info1-mob.png?w=498&h=280&format=webp')
  },
  {
    class: 'info__box--orange',
    toggle: 'box2-active',
    title: 'Create',
    mark: 'token',
    text: 'Create your own crypto token easily and quickly with the 1Dapp token generator. Choose from various token templates or build a custom one with all possible options.',
    image: require('@/assets/images/useful/info/info2-mob.png?w=498&h=280&format=webp')
  },
  {
    class: 'info__box--violet',
    toggle: 'box3-active',
    title: 'Add',
    mark: 'extras',
    text: 'Launch the best crypto token with 1Dapp, and our partners will help. If you need something unique or special for your project, contact us! We can help with everything—smart contracts, NFTs, designs, or even marketing & launch assistance.',
    image: require('@/assets/images/useful/info/info3-mob.png?w=498&h=280&format=webp')
  }
]

function toggleActiveCard(activeClass: string) {
  activeCard.value = activeClass
}
</script>

<template>
  <div class="info">
    <div class="container">
      <div class="info__inner" :class="activeCard">
        <div class="info__side">
          <div class="info__top">
            <h2 class="section-title">Create a ready-to-launch crypto project</h2>
          </div>
          <div class="info__list">
            <div
              v-for="(card, index) in infoCards"
              @mouseenter="toggleActiveCard(card.toggle)"
              class="info__box"
              :class="card.class"
              :key="index"
            >
              <div class="info__box-side">
                <h3 class="info__box-title">
                  {{ card.title }} <span>{{ card.mark }}</span>
                </h3>
                <div class="info__box-content">
                  <div class="info__box-inner">
                    <p>
                      {{ card.text }}
                    </p>
                    <div class="info__box-actions">
                      <button type="button" class="btn btn--primary"><span>Create project</span></button>
                      <button type="button" class="btn btn--tertiary"><span>Learn More</span></button>
                    </div>
                  </div>
                </div>
              </div>

              <Picture class="info__box-img">
                <img :src="card.image" :alt="card.title" width="498" height="280" />
              </Picture>
            </div>
          </div>
        </div>
        <div class="info__images">
          <Picture class="info__images-item">
            <img
              src="@/assets/images/useful/info/info1.png?w=1026&h=610&format=webp"
              width="1026"
              height="610"
              alt="img"
            />
          </Picture>

          <Picture class="info__images-item">
            <img
              src="@/assets/images/useful/info/info2.png?w=1026&h=610&format=webp"
              width="1026"
              height="610"
              alt="img"
            />
          </Picture>

          <Picture class="info__images-item">
            <img
              src="@/assets/images/useful/info/info3.png?w=1026&h=610&format=webp"
              width="1026"
              height="610"
              alt="img"
            />
          </Picture>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
