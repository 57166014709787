<script setup lang="ts">
import { ref } from 'vue'
import { Collapse } from 'vue-collapsed'

import Icon from '@1dapp/ui/components/Icon.vue'

const DOCS = import.meta.env.VITE_DOCS_URL

const cards = [
  {
    title: 'Do I need to know coding to use 1Dapp?',
    content: `
      <p>
        Nope. You do not need it at all. It works the same way for websites and contracts. With unique 1Dapp generators, you can easily create everything. All interfaces are intuitively understandable and have descriptions.
      </p>
    `,
    isExpanded: false
  },
  {
    title: 'Is it free to create a website?',
    content: `
      <p>
        You can create it for free. But to use your domain and delete our branding, you need to purchase a website.
      </p>
    `,
    isExpanded: false
  },
  {
    title: 'Will there be more templates?',
    content: `
      <p>
        Yes! We always work on future updates, including new templates for different crypto themes. But if you need something right now, just contact us.
      </p>
    `,
    isExpanded: false
  },
  {
    title: 'Can I change my website later?',
    content: `
      <p>
        Yes, absolutely. You can make changes to your website anytime; just use the interface and upload the newest version of the website.
      </p>
    `,
    isExpanded: false
  },
  {
    title: 'Is it possible to change the contract of the token?',
    content: `
      <p>
        Be careful when creating your crypto token contract. Some of token parameters cannot be changed later (you can see that in the descriptions). After contract deployment, you can change taxes, anti-whale, anti-bot, blacklists, whitelists, etc. in the contracts dashboard.
      </p>
    `,
    isExpanded: false
  },
  {
    title: 'I want to launch my own crypto project. What do I need to do?',
    content: `
      <p>
        Create a website using 1Dapp website generator. Generate a crypto token using 1Dapp. Create social media, choose launch method, and push marketing! Need help with any of these steps? Contact us. (<a href="https://docs.1dapp.io/contacts" target="_blank">https://docs.1dapp.io/contacts</a>)
      </p>
    `,
    isExpanded: false
  },
  {
    title: 'What are the payment methods available?',
    content: `
      <p>
        Currently, you can pay with main BSC and ETH stablecoins. Make sure that you are sending the right amount in the right tokens (do not worry, on the payment step you will see a help hint)
      </p>
    `,
    isExpanded: false
  },
  {
    title: 'Do I need to pay for hosting?',
    content: `
      <p>
        Nope, hosting is free. You can use integrated free hosting for 3 months.
      </p>
    `,
    isExpanded: false
  }
]

const cardsExpanded = ref<{
  [key: string]: boolean | undefined
}>({})

function toggleExpand(index: number) {
  cardsExpanded.value[index] = !cardsExpanded.value[index]
}
</script>

<template>
  <div class="faq">
    <div class="container">
      <div class="faq__inner">
        <div class="faq__top">
          <h2 class="section-title">Frequently Asked Questions</h2>

          <span class="section-subtitle">
            Find answers to the most popular questions about 1Dapp below. If you have any extra questions, check out our
            <a :href="DOCS" target="_blank">docs</a> or contact us
          </span>
        </div>

        <div class="faq__list">
          <div
            v-for="(card, index) in cards"
            class="faq__collapse"
            :class="{ 'is-active': !!cardsExpanded[index] }"
            :key="index"
          >
            <button @click="toggleExpand(index)" type="button" class="faq__collapse-trigger">
              <span>{{ card.title }}</span>
              <span class="faq__collapse-trigger-icon">
                <Icon src="plus" />
                <Icon src="minus" />
              </span>
            </button>
            <div class="faq__collapse-content">
              <Collapse :when="!!cardsExpanded[index]" class="faq__collapse-inner">
                <div v-html="card.content" class="faq__collapse-body"></div>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.faq__collapse-content {
  display: block;
}
</style>
