<script setup lang="ts">
import { Vue3Marquee } from 'vue3-marquee'

import Picture from '@/components/Picture.vue'

const slides = [
  {
    text: "I created my first ever crypto project with 1Dapp; you ain't believe that was crazy easy. I will continue investing in crypto and creating more cool projects.",
    name: 'Alex',
    image: require('@/assets/images/useful/testimonials/person1.png?w=48&h=48&format=webp')
  },
  {
    text: "1Dapp - is my time saver. It's a great thing that, even so quickly, you can create a unique, fast, and fully mobile compatible website.",
    name: 'Fernando',
    image: require('@/assets/images/useful/testimonials/person2.png?w=48&h=48&format=webp')
  },
  {
    text: 'Very simple and understandable interface, but you can create a unique, fast, and fully mobile-compatible website within 1 hour.',
    name: 'Muhamad',
    image: require('@/assets/images/useful/testimonials/person3.png?w=48&h=48&format=webp')
  },
  {
    text: 'I am super happy that someone finally created such a great project. The life of a crypto admin became very easy.',
    name: 'Anonymus',
    image: require('@/assets/images/useful/testimonials/person4.png?w=48&h=48&format=webp')
  },
  {
    text: 'It is much cheaper now to launch a successful project. You do not need to pay a lot of money for the developers and designers.',
    name: 'BNB whale',
    image: require('@/assets/images/useful/testimonials/person5.png?w=48&h=48&format=webp')
  },
  {
    text: 'I have created some crypto projects before, and they cost me a lot of money. With 1Dapp, my costs are down dramatically. In terms of budget and time savings, this is a perfect tool.',
    name: 'Chen',
    image: require('@/assets/images/useful/testimonials/person6.png?w=48&h=48&format=webp')
  },
  {
    text: 'Usually, before, I worked as marketing manager and could not afford my own crypto project. My dreams come true with 1Dapp. No-code generators will create everything for you; then, just launch marketing and social media.',
    name: 'Sarah',
    image: require('@/assets/images/useful/testimonials/person8.png?w=48&h=48&format=webp')
  }
]
</script>

<template>
  <div class="expectations">
    <div class="container">
      <div class="expectations__top">
        <h2 class="section-title">Beyond expectations</h2>
        <span class="section-subtitle">
          Many clients have already worked with us to develop their crypto projects. To create a successful crypto
          token, harness the power of 1Dapp
        </span>
      </div>
    </div>
    <Vue3Marquee :pause-on-hover="true" class="expectations__carousel">
      <div v-for="(slide, index) in slides" class="expectations__slide" :key="index">
        <div class="expectations__slide-text">
          <p>{{ slide.text }}</p>
        </div>
        <div class="expectations__slide-bottom">
          <Picture>
            <div class="expectations__slide-img" :style="`background-image: url(${slide.image})`"></div>
          </Picture>
          <div class="expectations__slide-name">{{ slide.name }}</div>
        </div>
      </div>
    </Vue3Marquee>
  </div>
</template>

<style lang="scss" scoped>
.expectations__carousel.vue3-marquee.horizontal {
  overflow-x: unset !important;
}

.expectations__slide {
  margin-right: 48px;
  height: 100%;
}

@media (max-width: 767px) {
  .expectations__slide {
    max-width: 38rem;
    margin-right: 15px;
  }
}
</style>
