<script setup lang="ts">
import { ref } from 'vue'

import Picture from '@/components/Picture.vue'
import Tabs from '@/components/Tabs.vue'

enum TabsTypes {
  Sections,
  Assets,
  Styles,
  Content,
  Library,
  Adaptive,
  CustomDomain,
  Social
}

const tabOptions = [
  { value: TabsTypes.Sections, label: 'Sections' },
  { value: TabsTypes.Assets, label: 'Assets' },
  { value: TabsTypes.Styles, label: 'Styles' },
  { value: TabsTypes.Content, label: 'Content' },
  { value: TabsTypes.Adaptive, label: 'Adaptive' },
  { value: TabsTypes.CustomDomain, label: 'CustomDomain' },
  { value: TabsTypes.Social, label: 'Social' }
]

const tabsItems = [
  {
    title: 'Choose from 100+ components and layouts',
    text: 'Choose from various templates and build a unique crypto website. We have templates for everything: menus, headings, contracts, taxes, tokenomics, socials, roadmaps, FAQs, etc.',
    image: require('@/assets/images/useful/tabs/img1.png?w=810&h=496&format=webp'),
    type: TabsTypes.Sections
  },
  {
    title: 'Make your section Global',
    text: 'Modify a section once and see the change reflected everywhere. A valuable feature for expanding websites.',
    image: require('@/assets/images/useful/tabs/img2.png?w=810&h=496&format=webp'),
    type: TabsTypes.Assets
  },
  {
    title: 'Easily Customize Styles',
    text: 'Customize heaven. You can edit colors, fonts, sizes, corner radiuses, button templates, and much more!',
    image: require('@/assets/images/useful/tabs/img3.png?w=810&h=496&format=webp'),
    type: TabsTypes.Styles
  },
  {
    title: 'Library',
    text: 'Add all the needed images and GIFs using a simple drag-and-drop function. Upload your pictures or use templates.',
    image: require('@/assets/images/useful/tabs/img4.png?w=810&h=496&format=webp'),
    type: TabsTypes.Content
  },
  {
    title: 'Default Mobile adaptability',
    text: '100% mobile-friendly for all different types of mobile platforms. Get a comfortable and easy-to-use experience with our adaptive templates.',
    image: require('@/assets/images/useful/tabs/img5.png?w=810&h=496&format=webp'),
    type: TabsTypes.Adaptive
  },
  {
    title: 'Social Network',
    text: 'Connect all the social networks needed for your crypto project. Paste the link through the social interface, and then you can use that link all across the generator.',
    image: require('@/assets/images/useful/tabs/img6.png?w=810&h=496&format=webp'),
    type: TabsTypes.Social
  },
  {
    title: 'Custom Domain',
    text: 'Connect* your own domain and publish a website with your branding. *Available after payment.',
    image: require('@/assets/images/useful/tabs/img7.png?w=810&h=496&format=webp'),
    type: TabsTypes.CustomDomain
  }
]

const activeTab = ref(TabsTypes.Sections)
</script>

<template>
  <div class="about">
    <div class="about__inner">
      <div class="about__top">
        <h2 class="section-title">Create crypto project with no-code generator</h2>
        <span class="section-subtitle">You can choose different options with many cool templates</span>
      </div>
      <Tabs :options="tabOptions" v-model="activeTab" />
      <div class="about__content">
        <div
          v-for="item in tabsItems"
          class="about__box"
          :class="{ 'is-active': activeTab === item.type }"
          :key="item.type"
        >
          <div class="about__box-inner">
            <div class="about__box-info">
              <div class="about__box-title">{{ item.title }}</div>
              <p class="about__box-text">{{ item.text }}</p>
            </div>
            <Picture class="about__box-image">
              <img :src="item.image" :alt="item.title" width="810" height="496" />
            </Picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
