<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'

import { RoutesEnum } from '@/config/router.config'

let currentRotation = ref(45)
const APP_URL = import.meta.env.VITE_APP_URL

function handleImageRotation() {
  const currentScrollTop = window.scrollY
  currentRotation.value = 45 - Math.min(currentScrollTop, 301) * (45 / 301)
}

onMounted(() => {
  window.addEventListener('scroll', handleImageRotation)
})
onUnmounted(() => {
  window.removeEventListener('scroll', handleImageRotation)
})
</script>

<template>
  <div class="banner">
    <div class="banner__dekor"></div>
    <div class="banner__inner">
      <div class="container">
        <div class="banner__top">
          <h1 class="section-title">Create your own crypto project in less than 25 Minutes!</h1>
          <span class="section-subtitle">No-code website & token contract generator</span>
          <div class="banner__actions">
            <a :href="APP_URL" target="_blank" class="btn btn--primary btn--lg"><span>Create Crypto Project</span></a>
            <RouterLink :to="{ name: RoutesEnum.Default, hash: '#info' }" class="btn btn--secondary btn--lg">
              Explore
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
    <div class="banner__img">
      <img
        src="@/assets/images/useful/banner-img.png?w=1400&h=787&format=webp"
        alt="Main banner"
        width="1400"
        height="787.5"
        class="banner__img-item"
        :style="`transform: rotateX(${currentRotation}deg)`"
      />
    </div>
  </div>
</template>

<style scoped>
/* .banner__img {
  margin: 0 auto;
} */
</style>
