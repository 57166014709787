import type { RoutesEnum } from '@/config/router.config'

export enum ServiceClassesEnum {
  BLUE = 'services__item--blue',
  ORANGE = 'services__item--orange',
  VIOLET = 'services__item--violet'
}

export type ServiceType =
  | {
      title: string
      text: string
      image: string
      demo?: { name: RoutesEnum }
      docs?: string
      colorClass: ServiceClassesEnum
    }
  | {
      title: string
      colorClass: ServiceClassesEnum
    }
