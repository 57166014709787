<script setup lang="ts">
import BaseServiceCard from '@/components/home/BaseServiceCard.vue'
import { RoutesEnum } from '@/config/router.config'
import { ServiceClassesEnum, type ServiceType } from '@/types'

const DOCS = import.meta.env.VITE_DOCS_URL
const services: Array<ServiceType> = [
  {
    title: 'Smart <br /> contracts',
    colorClass: ServiceClassesEnum.BLUE
  },
  {
    title: 'Standart pool',
    text: 'All types of single-token staking pools',
    image: require('@/assets/images/useful/svg/staking.svg?inline'),
    docs: DOCS + '/extra-services/smart-contracts/token-staking-pools',
    colorClass: ServiceClassesEnum.BLUE
  },
  {
    title: 'Fixed APY pool',
    text: 'All Types of LP Staking Pools',
    image: require('@/assets/images/useful/svg/farm.svg?inline'),
    docs: DOCS + '/extra-services/smart-contracts/lp-farm-staking-pools',
    colorClass: ServiceClassesEnum.BLUE
  },
  {
    title: 'Limited pool',
    text: 'Staking pool with lock time',
    image: require('@/assets/images/useful/svg/clock.svg?inline'),
    docs: DOCS + '/extra-services/smart-contracts/staking-with-lock',
    colorClass: ServiceClassesEnum.BLUE
  },
  {
    title: 'Ordered staking',
    text: 'Powerful staking with different parameters',
    image: require('@/assets/images/useful/svg/ordered-staking.svg?inline'),
    docs: DOCS + '/extra-services/smart-contracts/binance-themed-staking',
    colorClass: ServiceClassesEnum.BLUE
  },
  {
    title: 'Swap',
    text: 'Integrate your own swap (our router)',
    image: require('@/assets/images/useful/svg/swap.svg?inline'),
    docs: DOCS + '/extra-services/smart-contracts/swap',
    demo: {
      name: RoutesEnum.DemoNftSwap
    },
    colorClass: ServiceClassesEnum.BLUE
  },
  {
    title: 'NFT',
    colorClass: ServiceClassesEnum.ORANGE
  },
  {
    title: 'NFTs',
    text: 'Any type of NFT (as ERC-721 or as ERC-20)',
    image: require('@/assets/images/useful/svg/erc.svg?inline'),
    docs: DOCS + '/extra-services/nft/nfts',
    colorClass: ServiceClassesEnum.ORANGE
  },
  {
    title: 'NFT box',
    text: 'A lucky box with NFTs inside',
    image: require('@/assets/images/useful/svg/box.svg?inline'),
    demo: { name: RoutesEnum.DemoNftBox },
    docs: DOCS + '/extra-services/nft/nft-box',
    colorClass: ServiceClassesEnum.ORANGE
  },
  {
    title: 'NFT staking',
    text: 'Stake NFT, get tokens',
    image: require('@/assets/images/useful/svg/polygons.svg?inline'),
    docs: DOCS + '/extra-services/nft/nft-staking',
    demo: {
      name: RoutesEnum.DemoNftStaking
    },
    colorClass: ServiceClassesEnum.ORANGE
  },
  {
    title: '50/50 game',
    text: 'Any type of 50/50 game (with design)',
    image: require('@/assets/images/useful/svg/games.svg?inline'),
    docs: DOCS + '/extra-services/smart-contracts/50-50-games',
    demo: {
      name: RoutesEnum.DemoNftGame
    },
    colorClass: ServiceClassesEnum.ORANGE
  },
  {
    title: 'Crafting/charming',
    text: 'Upgrade NFTs to better ones',
    image: require('@/assets/images/useful/svg/tokens.svg?inline'),
    docs: DOCS + '/extra-services/nft/crafting-charming',
    demo: {
      name: RoutesEnum.DemoNftCrafting
    },
    colorClass: ServiceClassesEnum.ORANGE
  },
  {
    title: 'Extras',
    colorClass: ServiceClassesEnum.VIOLET
  },
  {
    title: 'Logo',
    text: 'Our designer can draw any logo in any style.',
    image: require('@/assets/images/useful/svg/stars.svg?inline'),
    docs: DOCS + '/extra-services/non-coding-extras/logo',
    colorClass: ServiceClassesEnum.VIOLET
  },
  {
    title: 'Website design',
    text: 'We will create a unique and professional design',
    image: require('@/assets/images/useful/svg/website.svg?inline'),
    docs: DOCS + '/extra-services/non-coding-extras/website-design',
    colorClass: ServiceClassesEnum.VIOLET
  },
  {
    title: 'Marketing',
    text: 'Help with all types of marketing TG, Twitter, YouTube, etc.',
    image: require('@/assets/images/useful/svg/marketing.svg?inline'),
    docs: DOCS + '/extra-services/non-coding-extras/marketing',
    colorClass: ServiceClassesEnum.VIOLET
  },
  {
    title: 'Market making',
    text: 'Increase your volume, buys, and holders',
    image: require('@/assets/images/useful/svg/staking.svg?inline'),
    docs: DOCS + '/extra-services/non-coding-extras/market-making',
    colorClass: ServiceClassesEnum.VIOLET
  },
  {
    title: 'Ad messages',
    text: 'Send a lot of AD messages and get more users',
    image: require('@/assets/images/useful/svg/spam.svg?inline'),
    docs: DOCS + '/extra-services/non-coding-extras/ad-messages',
    colorClass: ServiceClassesEnum.VIOLET
  },
  {
    title: 'Launch help',
    text: 'Get help with the launch on the PinkSale',
    image: require('@/assets/images/useful/svg/rocket.svg?inline'),
    docs: DOCS + '/extra-services/non-coding-extras/launch-help',
    colorClass: ServiceClassesEnum.VIOLET
  }
]
</script>

<template>
  <div class="services">
    <div class="services__inner">
      <div class="services__top">
        <h2 class="section-title">Extra services</h2>

        <span class="section-subtitle">
          Need something unique, need help with something, or want the best service? Order with 1Dapp. We are ready to
          develop, design, create, and help. Contact us, and let’s find out what suits you best.
        </span>
      </div>

      <div class="services__grid">
        <BaseServiceCard v-for="(service, index) in services" :item="service" :key="index" />

        <div class="services__item is-transparent">
          <button type="button" class="services__btn"><span>Talk to sales </span></button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
